import React from "react";
import _get from "lodash.get";
import { graphql } from "gatsby";

import PageTemplate from "app/templates/page";

const Page = (props) => {
  const frontmatter = _get(props, "data.page.frontmatter", {});
  return (
    <PageTemplate
      {...frontmatter}
      html={_get(props, "data.page.html")}
      template={_get(props, "data.page.frontmatter.template")}
    />
  );
};

export default Page;

export const query = graphql`
  query ($id: String) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        template
        slug
        title
        seo {
          title
          desc
        }
        intro
      }
      html
    }
  }
`;
