import React from "react";
import _get from "lodash.get";

import SEOMeta, { seoField } from "app/components/seo";
import Layout from "app/components/layout";
import IntroBlock from "app/components/intro-block";

import * as shared from "app/styles/shared";
import * as styles from "app/components/page.styles";

const S = {
  ...shared,
  ...styles,
};

const Page = ({ html, template, ...props }) => (
  <Layout>
    <SEOMeta title={_get(props, "title")} {..._get(props, "seo")} />
    <S.Container as="article">
      {template === "legal" && (
        <IntroBlock
          title={_get(props, "title")}
          intro={_get(props, "intro")}
          headerEl="h1"
          titleEl="small"
          introEl="span"
        />
      )}

      {html && <S.PageContent dangerouslySetInnerHTML={{ __html: html }} />}
    </S.Container>
  </Layout>
);

export default Page;

export const pageFields = [
  {
    label: "Template",
    name: "template",
    widget: "select",
    default: "standard",
    options: ["legal", "standard"],
  },
  seoField,
  {
    label: "Title",
    name: "title",
    widget: "string",
    hint: "This field is used only in the admin panel",
  },
  {
    label: "Intro",
    name: "intro",
    widget: "text",
    required: false,
  },
  {
    label: "Slug",
    name: "slug",
    widget: "string",
    hint: "Relative page path. (e.g. '/extras', '/annoucement', etc.)",
  },
  {
    label: "Content",
    name: "body",
    widget: "markdown",
  },
];
